<template>
    <div class="loading">
        <van-skeleton v-for="(item) in len" :row="row"  :key="item" />

    </div>
</template>
<script>
  import { Skeleton } from 'vant'
  export default {
    name: 'loading',
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      isMobile: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        row: this.isMobile ? 5 : 3,
        len: this.isMobile ? 3 : 2
      }
    },
    components: {
      [Skeleton.name]: Skeleton
    }
  }
</script>
<style lang="scss">
.van-skeleton__row,
.van-skeleton__title{
    background: #f5f5f5;
    height: 12px;
    border-radius: 4px;
}
.van-skeleton{
  padding: 0;
}
.van-skeleton__content{
    padding: 30px 0;
    &>.van-skeleton__row:nth-last-child(1){
        width: 160px;
    }
}
</style>
