import { getUrlParamByName } from '@/utils/common'
// import WBAppSdk from '@/utils/wb-app-sdk.js'
import config from '@/config/index'

class langHelper {
  static localStroageKey = 'application_language'

  // 获取首次语言设置
  static getInitLocale() {
    let locale = localStorage.getItem(this.localStroageKey) || config.lang || navigator.language
    if (window.location.href.indexOf('lang=') !== -1) {
      locale = getUrlParamByName('lang')
    }
    return locale
  }

  // 格式化
  static langKeyformat(key, beforeSymbol = '-', afterSymbol = '_') {
    if (key) {
      let resKey = key.replace(RegExp(beforeSymbol, 'g'), afterSymbol) // 转换
      if (resKey.match(RegExp(afterSymbol)) && resKey.split(afterSymbol).length === 2) {
        const strArr = resKey.split(afterSymbol)
        resKey = strArr[0].toLowerCase() + afterSymbol + strArr[1].toUpperCase()
        return resKey
      } else {
        return 'NotSupported'
      }
    } else {
      return null
    }
  }

  // 设置本地语言信息
  static setLocale(key) {
    if (key) {
      document.querySelector('html').lang = key
      localStorage.setItem(this.localStroageKey, key)
    }
  }
}

export default langHelper
