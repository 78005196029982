<template>
  <div class="wb-lang">
    <button class="down-btn pc-down" @click="toDownPage">{{ $t('common.downLoadText') }}APP</button>
    <div class="pc-lang-config">
      <a
        class="lang-txt"
        :class="{ selected: i18n.locale === 'zh_CN' }"
        href="javascript:;"
        @click="changeLang('zh_CN')"
        >简</a
      >
      <span class="v-line" href="javascript:;" >|</span>
      <a
        class="lang-txt"
        :class="{ selected: i18n.locale === 'zh_HK' }"
        href="javascript:;"
        @click="changeLang('zh_HK')"
        >繁</a
      >
    </div>
  </div>
</template>

<script>
  import { downPage } from '@/utils/index'
  import i18n from '@/language'
  export default {
    name: 'wb-lang',

    props: {
      showLang: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        i18n: i18n,
        showDropPanel: false
      }
    },

    mounted() {
      const _this = this
      document.body.addEventListener(
        'click',
        function() {
          _this.showDropPanel = false
        },
        false
      )
    },

    methods: {
      // 切换语言
      changeLang(lang) {
        if (i18n.locale === lang) return
        i18n.locale = lang
        this.showDropPanel = false
        window.location.reload()
      },

      toggleLangPicker() {
        this.showDropPanel = !this.showDropPanel
      },
      toDownPage() {
        downPage()
      }
    }
  }
</script>

<style lang="scss" scoped>
.wb-lang {
  white-space: nowrap;
}
.down-btn {
  padding: 8px 24px;
  background: #fe9007;
  border-radius: 18px;
  color: #fff;
  text-align: center;
  border: none;
  margin-right: 20px;
  white-space: nowrap;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background: #fea639;
  }
  &:active {
    background: #ea8406;
  }
}

.pc-lang-config {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;

  .lang-txt {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999;

    &.selected {
      color: #333;
    }
  }

  .v-line {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333;
    margin: 0 6px;
  }
}

@media screen and (max-width:1024px) {
  .down-btn {
      font-size: 12px;
    }
}
@media screen and (max-width: 810px) {
  .pc-lang-config,.down-btn {
    display: none;
  }
}
</style>
