import Storage from 'good-storage'
import Config from '@/config/index.js'
import UAParser from 'ua-parser-js'
import { v1 as uuidv1 } from 'uuid'
// 根据参数名获取url参数（兼容vue）
export function getUrlParamByName(queryName) {
  const reg = new RegExp('(^|&)' + queryName + '=([^&]*)(&|$)', 'i')
  const r = window.location.href.match(reg)
  if (r != null) {
    const tmp = decodeURI(r[2])
    if (tmp.includes('#/')) return tmp.split('#/')[0]
    else return decodeURI(r[2])
  } else {
    return null
  }
}

export function currentUrlToParams(key = null) {
  const paramsUrl = window.location.href.split('?')
  if (paramsUrl.length < 2) return key ? null : {}
  const paramsArr = paramsUrl[1].split('&')
  const paramsData = {}
  paramsArr.forEach(r => {
    const data = r.split('=')
    paramsData[data[0]] = data[1]
  })
  if (key) return Object.hasOwnProperty.call(paramsData, key) ? paramsData[key] : null
  return paramsData
}

/**
 * 将对象转换为?a=5&b=7形式
 * @param obj
 * @param firstStr
 * @returns {string|string}
 */
export function obj2StrParams(obj, firstStr = '?') {
  let params = firstStr

  for (const p in obj) {
    params += p + '=' + obj[p] + '&'
  }
  return params.substring(0, params.length - 1)
}
/*
获取当前url
http://192.168.49.71:8081/ => http://192.168.49.71:8081/
http://192.168.49.71:8081/mm/ => http://192.168.49.71:8081/mm/
http://192.168.49.71:8081/mm/index.html => http://192.168.49.71:8081/mm/
http://192.168.49.71:8081/mm/ff/login.html?id=55 => http://192.168.49.71:8081/mm/ff/

页面名字不能能匹配除\w 的地址，也就是说你的页面名字名字必须由a-z、A-Z、0-9，以及下划线组成才可以。
*/
export function getCurrentUrl() {
  const allUrl = window.location.href
  const match = allUrl.match(/(\S+\/)\w+.html/i)
  return match && Array.isArray(match) && match.length > 1 ? match[1] : allUrl
}

/**
 * 重置对象（会修改原始对象）
 * @param object
 * @param defaultVal
 */
export function resetObject(object, defaultVal = {}) {
  for (const k in object) {
    if (Object.hasOwnProperty.call(defaultVal, k)) {
      object[k] = defaultVal[k]
    } else {
      if (Array.isArray(object[k])) object[k] = []
      if (typeof object[k] === 'string') object[k] = ''
      if (typeof object[k] === 'number') object[k] = null
      if (typeof object[k] === 'boolean') object[k] = false
    }
  }
}

/**
 * 对象赋值（会修改原始对象）
 * @param object
 * @param valObject
 */
export function fillerLeft(object, valObject = {}) {
  for (const k in object) {
    if (Object.hasOwnProperty.call(valObject, k)) {
      object[k] = valObject[k]
    }
  }
}

/**
 * 获取用户信息
 * @param key
 * @returns {null|*|undefined|{}}
 */
export function getUserInfo(key = null) {
  const userInfo = Storage.get(Config.userInfoKey) || {}
  if (key) return Object.hasOwnProperty.call(userInfo, key) ? userInfo[key] : null
  return userInfo || {}
}

/**
 * 设置用户信息
 * @param user
 * @returns {*}
 */
export function setUserInfo(user) {
  Storage.set(Config.userInfoKey, user)
  return user
}

/**
 * 获取Token
 * @returns {*|undefined}
 */
export function getToken() {
  return Storage.get(Config.tokenKey)
}

/**
 * 设置Token
 * @param token
 * @returns {*|undefined}
 */
export function setToken(token) {
  return Storage.set(Config.tokenKey, token)
}

/**
 * 移除Token
 * @returns {*}
 */
export function removeToken() {
  return Storage.remove(Config.tokenKey)
}
/**
 * 获取浏览器本机信息, UID 用 UUID 生成存储在 localStorage 中
 */
export function getLocalInfo() {
  return {
    deviceUdid: getWbKey(),
    deviceName: UAParser().device.model || UAParser().browser.name + ':' + UAParser().browser.version,
    devicePlatform: 'Android',
    devicePlatformVersion: UAParser().os.version
  }
}

export function getCustomUA() {
  const localInfo = getLocalInfo()
  return `h5|1.0.0|1|${localInfo.deviceName}|${localInfo.devicePlatform}|${localInfo.devicePlatformVersion}|${localInfo.deviceUdid}|${'package'}`
}

export function getWbKey() {
  const WB_KEY = 'winbull_device_key'
  let wbKey = localStorage.getItem(WB_KEY)
  if (!wbKey) {
    wbKey = uuidv1({
      msecs: new Date().getTime()
    }).replace(/-/g, '')
    localStorage.setItem(WB_KEY, wbKey)
  }
  return wbKey
}
/**
 * 跳转到下载页
 */
export function downPage() {
  const origin = window.location.origin
  let link = ''
  const isPc = window.innerWidth > 810
  if (origin.includes('dev') || origin.includes('uat') || origin.includes('sit')){
    link = origin + `/h5/WinBullWebsite/${isPc ? 'downPc' : 'downMobile'}.html`
  } else {
    link = `https://winbull.vip/${isPc ? 'downPc' : 'downMobile'}.html`
  }
  window.open(link)
}
