<template>
  <div class="wb-footer">
    <div class="pc-foot">
      <div class="contact">
        <p>
          <span>
            <em class="title">{{ $t('footer.connectUs') }}</em>
            <em class="phone">{{ $t('footer.serviceCall') }}&nbsp;&nbsp;&nbsp;&nbsp;400 801 6688</em>
            <em class="trade-time">{{ $t('footer.officeTime') }}&nbsp;&nbsp;&nbsp;&nbsp;交易日 09:00-18:00</em>
          </span>
          <span>
            <em class="title">{{ $t('footer.tksm') }}</em>
            <em class="cursor xieyi" @click="openPdf('fwtk')">{{ $t('footer.fwtk') }}</em>
            <em class="cursor xieyi" @click="openPdf('yszc')">{{ $t('footer.yszc') }}</em>
          </span>
        </p>
        <p class="code"><img src="@/assets/images/common/chat_code.png" /></p>
      </div>
      <div class="info">
        <span>{{ $t('footer.ba') }}</span><em class="line">｜</em><span>{{ $t('footer.cy') }}</span><em
          class="line">｜</em><span>{{ $t('footer.warning') }}</span>
      </div>
    </div>
    <div class="mobile-foot">
      <div class="contact">
        <p>
          <em class="title">{{ $t('footer.connectUs') }}</em>
          <em class="phone">400 801 6688</em>
          <em class="trade-time" style="margin-bottom: 0;">交易日 09:00-18:00</em>
        </p>
        <p class="code"><img src="@/assets/images/common/chat_code.png" /></p>
      </div>
      <div class="info g9">
        <p>
          <span class="cursor xieyi" @click="openPdf('fwtk')">{{ $t('footer.fwtk') }}</span><em class="line">｜</em><span class="cursor xieyi" @click="openPdf('yszc')">{{
            $t('footer.yszc') }}</span>
        </p>
        <p><span>{{ $t('footer.cy') }}</span></p>
        <p><span>{{ $t('footer.ba') }}</span><em class="line">｜</em><span>{{ $t('footer.warning') }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'wb-footer',
    methods: {
      async openPdf(name) {
        const params = {
          fwtk: '服务条款',
          yszc: '隐私政策'
        }
        const encodeTitle = encodeURI(params[name])
        const url = `https://offical.winbull8.com/info/${encodeTitle}.pdf`
        window.open(url)
      }
    }
  }
</script>

<style lang="scss" scoped>
.wb-footer {
  padding: 30px 0% 0;
  background-color: #424242;
  width: 100%;

  em {
    font-style: normal;
  }

  .pc-foot {
    max-width: 1000px;
    margin: 0 auto;
    font-size: 12px;
    color: #fff;
    display: block;
    .contact {
      padding-bottom: 30px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      font-size: 13px;
      line-height: 18px;

      p:not(.code) {
        display: inline-flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 45%;
      }

      .title {
        padding-bottom: 4px;
      }
    }

    .info {
      padding: 20px 0;
      border-top: 1px solid rgba(255,255,255,0.1);;
      line-height: 18px;
      color: #999;
    }
  }
  .mobile-foot{
    display: none;
  }
}

.line {
  font-style: normal;
  display: inline-block;
  width: 12px;
  color: #cacaca;
  margin: 0 4px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  padding: 0;
}

.title {
  font-size: 16px;
  font-weight: 500;
  position: relative;
  margin-top: 0;
  height: 24px;
  line-height: 24px;

  &::before {
    content: '';
    height: 14px;
    border-right: 2px solid #0691ff;
    display: inline-block;
    border-radius: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

em {
  display: block;
  margin: 12px 0;
  padding-left: 8px;
  white-space: pre;
}

.code {
  width: 88px;
  display: inline-block;

  img {
    width: 100%;
  }
}

.g9 {
  color: #999;
}
.cursor{
  cursor: pointer;
}
.xieyi{
  &:hover{
    color: #333;
  }
}
@media (min-width:769px) and (max-width:1024px) {
  .wb-footer{
    padding: 24px 112px 0;
    .pc-foot{
      font-size: 10px;
      .contact{
        padding-bottom: 24px;
        line-height: 14px;
        align-items: center;
      }
    }
  }
  .title{
    font-size: 13px;
    line-height: 19px;
    &::before {
      content: '';
      height: 11px;
    }
  }
  .code{
    width: 71px;
  }
}
@media  (min-width:768px) and (max-width:768px) {
  .wb-footer{
    padding: 30px 35px 0;
  }
}
@media screen and (max-width:767px) {
  .wb-footer {
    padding-top: 40px;
    .pc-foot{
      display: none;
    }
    .mobile-foot{
      color: #fff;
      display: block;
    }
    .info {
      font-size: 13px;
      line-height: 20px;
      background-color: #4d4d4d;
      padding: 15px 20px 16px;
      p{
        margin-bottom: 4px;
      }
    }

    .contact {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0 20px 40px;
      line-height: 20px;

      p:first-child {
        span {
          display: block;
        }
      }

      .phone {
        font-size: 18px;
        font-weight: 600;
        margin-top: 16px;
        margin-bottom: 4px;
      }

      .trade-time {
        font-size: 14px;
      }
    }
  }
  em{
    margin: 4px 0;
  }
}</style>
