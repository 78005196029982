export const EmojiList = [
  '微笑',
  '疑问',
  '色',
  '奋斗',
  '吓',
  '酷',
  '流汗',
  '困',
  '想钱',
  '衰',
  '偷笑',
  '晕',
  '大哭',
  '害羞',
  '闭嘴',
  '心碎',
  '涨',
  '跌',
  '复盘',
  '买入',
  '卖出',
  '熊市',
  '牛市',
  '关灯吃面',
  '花',
  '满仓',
  '空仓',
  '啤酒',
  '茶',
  '抄底',
  '便便',
  '钱',
  '发',
  '中',
  '看空',
  '看多',
  '加仓',
  '减仓',
  '强',
  '弱',
  'OK',
  '炸弹',
  '礼物',
  '太阳'
]

export const EmojiList2 = [
  '微笑',
  '疑問',
  '色',
  '奮鬥',
  '流淚',
  '吐舌',
  '發怒',
  '嚇',
  '酷',
  '流汗',
  '困',
  '親親',
  '想錢',
  '哭',
  '衰',
  '驚恐',
  '偷笑',
  '擦汗',
  '暈',
  '折磨',
  '大哭',
  '害羞',
  '閉嘴',
  '心碎',
  '漲',
  '跌',
  '復盤',
  '買入',
  '賣出',
  '熊市',
  '牛市',
  '關燈吃麵',
  '花',
  '滿倉',
  '空倉',
  '啤酒',
  '茶',
  '抄底',
  '便便',
  '錢',
  '發',
  '中',
  '看空',
  '看多',
  '加倉',
  '減倉',
  '強',
  '弱',
  'OK',
  '炸彈',
  '禮物',
  '太陽'
]

export const EmojiRule = /\[[\u4E00-\u9FA5_a-zA-Z]+\]/g
export const EmojiRegExp = new RegExp('\\[(微笑|疑问|色|奋斗|流泪|吐舌|吓|酷|流汗|困|哭|衰|偷笑|晕|折磨|大哭|害羞|闭嘴|心碎|涨|跌|复盘|买入|卖出|熊市|牛市|关灯吃面|花|满仓|空仓|啤酒|茶|抄底|便便|钱|发|中|看空|看多|加仓|减仓|强|弱|OK|炸弹|礼物|太阳|疑問|奮鬥|流淚|發怒|嚇|想錢|暈|閉嘴|漲|復盤|買入|賣出|關燈吃麵|滿倉|空倉|錢|發|加倉|減倉|強|擊掌|炸彈|禮物|太陽|)\]')

