import Quill from 'quill'
import { sanitize } from 'quill/formats/link'

const Parchment = Quill.imports.parchment

const ATTRIBUTES = [
  'height',
  'width'
]

const EMOJI_API = 'https://winbull-img2.oss-cn-guangzhou.aliyuncs.com/emoji_64/$name$.png'

class Emoji extends Parchment.Embed {
  debugger
  static create(value) {
    const node = super.create(value)
    if (typeof value === 'string') {
      node.setAttribute('name', value)
      node.setAttribute('src', this.sanitize(EMOJI_API.replace('$name$', value)))
    }
    return node
  }

  static formats(domNode) {
    return ATTRIBUTES.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute)
      }
      formats['style'] = 'mobileWidth:0; mobileWidth: 20.4; mobileHeight: 25.5; mobileMargin: 0; mobileAlignment: center'
      return formats
    }, {})
  }

  static match(url) {
    return /\.(jpe?g|gif|png)$/.test(url) || /^data:image\/.+;base64/.test(url)
  }

  static sanitize(url) {
    return sanitize(url, ['http', 'https', 'data']) ? url : '//:0'
  }

  static value(domNode) {
    return domNode.getAttribute('name')
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}
Emoji.tagName = 'IMG'
Emoji.blotName = 'emoji'
Emoji.className = 'ql-emoji'

Quill.register('formats/emoji', Emoji, true)
export default Emoji
