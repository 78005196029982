<template>
  <div class="base-page">
    <div class="page-head">
      <wb-header/>
    </div>
    <div class="page-content" :style="contentStyle">
      <slot />
    </div>
    <wb-footer />
  </div>
</template>

<script>
  import WbHeader from '@/components/header/wb-header.vue'
  import WbFooter from '@/components/footer/wb-footer.vue'

  export default {
    name: 'base-page',
    components: {
      WbHeader,
      WbFooter
    },

    props: {
      // 是否是首页
      isHome: {
        type: Boolean,
        default: false
      },
      // 页面内容样式
      contentStyle: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        scrollTop: 0,
        bannerHeight: 0
      }
    }
  }
</script>

<style lang="scss" scoped>
.base-page {
  .page-head {
    position: fixed;
    top: var(--notice-bar);
    left: 0;
    width: 100%;
    z-index: 999;
  }

  .page-content {
    padding-top: calc(80px + var(--notice-bar));
    margin: 0 auto;
    min-height: calc(100vh - 154px);
  }
}
@media  (min-width: 768px) and (max-width:1024px) {
  .base-page {
    .page-content {
      padding-top: calc(64px + var(--notice-bar));
      min-height: calc(100vh - 185px);
    }
  }
}
// 当屏幕小于750px
@media screen and (max-width: 767px) {
  .base-page {
    .page-head{
      top: 0 !important;
    }
    .page-content {
      padding-top: calc(56px + var(--notice-bar));
      margin: 0 auto;
      min-height: calc(100vh - 400px);
    }
  }

  .wb-side-bar{
    display: none;
  }
}
</style>
