let reqApiUrl = ''
let lang = navigator.language // 设置浏览器默认字体

switch (process.env.VUE_APP_ENV) {
  case 'dev':
    reqApiUrl = window.location.protocol + '//' + window.location.host
    break
  case 'sit':
    reqApiUrl = window.location.protocol + '//' + window.location.host
    break
  case 'test':
    reqApiUrl = window.location.protocol + '//' + window.location.host
    break
  case 'prod':
  case 'prod_winbullcc':
  case 'prod_winbullcomcn':
  case 'prod_winbull8com':
    reqApiUrl = 'https://api.winbull8.com'
    break
  default:
    ;(reqApiUrl = '/devApi'), (lang = 'zh_CN')
    break
}

export { reqApiUrl, lang } //可以导出更多需要不同环境区分的url
