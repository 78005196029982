import { reqApiUrl, lang } from '@/config/api-url.js'

export default {
  version: 1.0,
  timeout: 30000,
  apiPrefix: '',
  requestRetry: 4,
  requestRetryDelay: 800,
  tokenKey: 'accessToken',
  userInfoKey: 'userInfo',
  apiUrl: reqApiUrl,
  corporation: '盈寶信息科技（廣州）有限公司',
  siteName: '盈領信息',
  designSize: 375, // 设计稿宽度 375,建议使用375，可以和一些主流的ui库兼容。如vant
  lang: lang,
}
