<template>
  <a href="./index.html" class="wb-header-logo">
    <img class="logo-pic" :src="logoPic" />
  </a>
</template>

<script>
  import i18n from '@/language'

  export default {
    name: 'wb-header-logo',

    props: {
      // 是否是暗黑样式
      darkMode: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      logoPic() {
        let logo
        if (i18n.locale === 'zh_CN') {
          logo = require('@/assets/images/common/logo_cn.png')
        } else {
          logo = require('@/assets/images/common/logo_hk.png')
        }
        return logo
      }
    }
  }
</script>

<style lang="scss" scoped>
.wb-header-logo {
  display: block;
  width: 147px;
  cursor: pointer;
}

.logo-pic {
  width: 100%;
}
@media screen and (max-width:767px) {
  .wb-header-logo {
    width: 98px;
  }
}
@media (min-width:768px) and (max-width:1024px) {
  .wb-header-logo {
    width: 119px;
  }
}

</style>
