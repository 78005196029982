import axios from '@/utils/request.js'
import { getCustomUA } from '@/utils'
// 获取app信息
export function getAppInfo() {
  return axios.get('/v1/user/sys-version/newest-release')
}
export function getApkInfo() {
  return axios.get('/v1/user/sys/data', { headers: {
    'Device-Info': getCustomUA()
  } })
}
// 获取重要消息
export function getImportantInfo(params) {
  return axios.get('v1/community/message/getMsgList', { params: params })
}
// 获取消息详情
export function noticeDetail(id){
  return axios.get(`/v1/community/message/detail/${id}`)
}
// 获取资讯栏目模块
export function categoryModule(params) {
  return axios.get('/v1/community/category-user/getCategoryList', { params: params })
}
// 获取资讯对应栏目文章数据
export function categoryArticleList(params) {
  return axios.post('/v1/community/community-article/findArticleListByPage', params)
}
// 搜索栏目文章
export function searchArticle(params) {
  return axios.get('/v1/community/quotes/getArticleQuotesList', { params: params })
}

// 获取搜索结果文章详情
export function articleDetail(params) {
  return axios.get(`/v1/community/community-article/getArticleInfo/${params.id}`)
}
// 帮助中心首页
export function helpListByQuery(params) {
  return axios.get('/v1/consult_web/question/indexByCategory', { params: params })
}
export function helpParentList(params) {
  return axios.get(`/v1/consult_web/question/${params.id}/menuChain?parentId=${params.pid}`)
}
// 获取帮助中心配置
export function helpResolve(params) {
  return axios.get(`/v1/consult_web/question/${params.id}`)
}
// 帮助中心搜索
export function helpSearch(params) {
  return axios.get(`/v1/consult_web/question/listByQuery`, { params: params })
}
// 获取公司简介
export function companyIntro() {
  return axios.get(`/v1/website/recruit/introduction`)
}
// 获取招聘列表
export function situationList(params) {
  return axios.post(`/v1/website/recruit/list`, params)
}
// 获取招聘详情
export function situationDetail(params) {
  return axios.get(`/v1/website/recruit/one?id=${params.id}`)
}
