<template>
  <div class="btn-content">
    <span class="down-btn" @click.stop="toDownPage" v-show="!isOpen">{{$t('common.downLoadText') }}APP</span>
    <div class="wb-menu-btn" :class="{ 'show-menu': isOpen }">
      <span class="line line-1" />
      <span class="line line-2" />
      <span class="line line-3" />
    </div>
  </div>
</template>

<script>
  import { downPage } from '@/utils/index'
  export default {
    name: 'wb-menu-btn',

    props: {
      isOpen: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      toDownPage() {
        downPage()
      }
    }
  }
</script>

<style lang="scss" scoped>
.wb-menu-btn {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 50px;

  .line {
    position: absolute;
    top: 25px;
    right: 0px;
    width: 18px;
    height: 1px;
    background-color: #26272b;
    transition: all 0.5s;
  }

  .line-1 {
    margin-top: -6px;
  }

  .line-3 {
    margin-top: 6px;
  }

  &.show-menu {
    .line-1 {
      transform: rotate(45deg) translate(5px, 3px);
    }

    .line-2 {
      transform: translate(50px, 0);
    }

    .line-3 {
      transform: rotate(-45deg) translate(5.4px, -3.5px);
    }
  }
}
.down-btn {
  display: inline-block;
  width: 72px;
  height: 24px;
  font-size: 13px;
  color: #fff;
  text-align: center;
  line-height: 24px;
  border-radius: 12px;
  background-color: #FE9007;
}
.btn-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
