<template>
  <div
:class="['imgage-content', { 'one-pic': this.imgList.length === 1 }]"
    :style="{ 'background': isMobile ? '#000' : 'rgba(0,0,0,0.5)' }">
    <template v-if="!isMobile">
      <span class="close-icon" @click="destroyViewer" />
      <div ref="imagesContent" id="imgsContent">
        <img v-for="(item, index) in imgList" :src="item" :key="index" />
      </div>
    </template>
  </div>
</template>
<script>
  import '@/styles/viewer.css'
  import Viewer from '@/utils/viewer.common.js'
  import { ImagePreview } from 'vant'
  export default {
    name: 'preview-images',
    props: {
      imgList: [],
      showIndex: Number,
      isMobile: {
        type: Boolean,
        default: false
      }
    },
    data: function() {
      return {
        viewer: null,
        index: this.showIndex
      }
    },
    watch: {
      imgList(nList) {
        if (nList) {
          this.$nextTick(() => {
            this.createViewer()
          })
        }
      }
    },
    mounted() {
      const _this = this
      this.$nextTick(() => {
        if (!this.isMobile) {
          this.createViewer()
        } else {
          ImagePreview({
            images: _this.imgList,
            startPosition: _this.showIndex,
            closeable: true,
            closeIcon: require('@imgs/common/icon_nav_close.png'),
            closeOnPopstate: true,
            beforeClose: () => false,
            onClose: () => {
              this.$emit('closePhotoModal', false)
            }
          })
        }
      })
    },
    unmounted() {
      this.destroyViewer()
    },
    methods: {
      destroyViewer() {
        this.viewer && this.viewer.destroy()
        this.$emit('closePhotoModal', false)
      },
      createViewer() {
        if (this.$refs.imagesContent) {
          this.viewer = new Viewer(this.$refs.imagesContent, {
            inline: true,
            fullscreen: false,
            loading: true,
            title: false,
            navbar: false,
            backdrop: true,
            loop: false,
            initialViewIndex: this.showIndex,
            keyboard: true,
            zIndex: 10000,
            container: document.querySelector('#imgsContent'),
            toolbar: {
              prev: this.imgList.length > 1,
              next: this.imgList.length > 1,
              zoomIn: true,
              zoomOut: true
            },

            view: res => {
              if (this.imgList.length > 1) {
                const index = res.detail.index + 1
                const footContainer = document.querySelector('.viewer-toolbar ul')
                const liElement = document.querySelector('.viewer-number')
                const li = document.createElement('li')
                li.setAttribute('class', 'viewer-number')
                li.style.cssText = 'color:#fff;font-size:16px;'
                li.innerHTML = `${index}/${this.imgList.length}`
                if (index === 1) {
                  document.querySelector('.viewer-prev').classList.add('viewer-prev-disabled')
                  document.querySelector('.viewer-next').classList.remove('viewer-next-disabled')
                } else if (index === this.imgList.length) {
                  document.querySelector('.viewer-next').classList.add('viewer-next-disabled')
                  document.querySelector('.viewer-prev').classList.remove('viewer-prev-disabled')
                }
                if (!liElement) {
                  footContainer.insertBefore(li, footContainer.childNodes[1])
                } else {
                  liElement.innerHTML = `${index}/${this.imgList.length}`
                }
              }
            }
          })
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.imgage-content {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  color: #fff;

  img {
    display: none;
  }

  ::v-deep .viewer-canvas>img {
    max-width: 700px !important;
    width: auto!important;
    left: 50%;
    margin-left: 0!important;
    transform: translateX(-50%) !important;
  }

  ::v-deep .viewer-fullscreen {
    display: none !important;
  }

  ::v-deep .viewer-toolbar {
    width: 262px;
    height: 40px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.4);
    margin: 0 auto;
    padding: 0 24px;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      height: 100%;
    }
  }

  &.one-pic {
    ::v-deep .viewer-toolbar {
      width: 128px;
    }
  }

  .close-icon {
    display: inline-block;
    position: absolute;
    right: 40px;
    top: 40px;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.4) url('../../../assets/images/common/icon_nav_close.png') center/20px 20px no-repeat;
    border-radius: 100%;
    cursor: pointer;
    z-index: 1001;

    &:hover {
      background: rgba(0, 0, 0, 0.4) url('../../../assets/images/common/icon_nav_close_hover.png') center/20px 20px no-repeat;
    }

    &:active {
      background: rgba(0, 0, 0, 0.4) url('../../../assets/images/common/icon_nav_close_click.png') center/20px 20px no-repeat;
    }
  }
}
</style>
<style>
.van-image-preview__index {
  font-size: 16px;
  left: 20px;
  font-size: 16px;
  transform: translateX(0);
}
</style>
