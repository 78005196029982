// 兼容的dom绑定事件
function addEvent(obj, type, fn) {
  if (obj.attachEvent) {
    // ie
    obj.attachEvent('on' + type, function() {
      fn.call(obj)
    })
  } else {
    obj.addEventListener(type, fn, false)
  }
}

window.addEvent = addEvent
