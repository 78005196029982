<template>
  <div class="download">
    <p>
      <span class="logo"><img :src="require('@imgs/common/app_icon.png')" alt="logo" /></span>
      <span><em>{{ $t('router.winbull') }}</em><br /><em>社交型智能券商</em></span>
    </p>
    <p class="btn" @click="openApp">{{ $t('common.open') }}APP</p>
  </div>
</template>
<script>

  export default {
    name: 'app-down',
    data() {
      return {
        iosLink: '',
        androidLink: ''
      }
    },
    methods: {
      openApp() {
        const env = window.location.origin
        let link = ''
        const isPc = window.innerWidth > 810
        if (env.includes('dev') || env.includes('test')){
          link = env + `/h5/WinBullWebsite/${isPc ? 'downPc' : 'downMobile'}.html`
        } else {
          link = `https://www.winbull.vip/${isPc ? 'downPc' : 'downMobile'}.html`
        }
        window.open(link)
      }
    },
    mounted() {
      this.$nextTick(() => {
        if (window.innerWidth <= 810) {
          document.querySelector('.wb-footer').style.paddingBottom = '60px'
        }
      })
    }
  }
</script>
<style lang="scss" scoped>
.download {
  width: 100%;
  height: 60px;
  background-color: #fff;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  box-shadow: 0px -2px 6px 0px rgba(238, 238, 238, 0.5);
  padding-bottom: calc(40px + constant(safe-area-inset-bottom));
  padding-bottom: calc(40px + env(safe-area-inset-bottom));

  span {
    display: inline-block;

    &.logo img {
      width: 36px;
      height: 36px;
      margin-right: 12px;
      vertical-align: bottom;
    }

    em {
      font-style: normal;

      &:first-child {
        font-size: 16px;
        color: #333;
      }

      &:nth-last-child(1) {
        color: #777;
        font-size: 12px;
      }
    }
  }

  .btn {
    width: 84px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 13px;
    color: #fff;
    background: #EB5550;
    border-radius: 19px;
    margin-top: 5px;
    cursor: pointer;
  }
}

@media screen and (min-width:769px) {
  .download {
    display: none;
  }
}

::v-deep {
  .wb-footer{
    padding-bottom: 100px;
  }
}
</style>
