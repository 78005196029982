import Vue from 'vue'
import VueI18n from 'vue-i18n'
import langHelper from './helper'
import zhCN from './zh-cn' // 简体中文
import zhHK from './zh-hk' // 繁体中文

Vue.use(VueI18n)

// 多语言内容
const messages = {
  zh_CN: zhCN,
  zh_HK: zhHK
}

let locale = langHelper.langKeyformat(langHelper.getInitLocale())
locale = Object.keys(messages).includes(locale) ? locale : 'zh_HK'
export default new VueI18n({ locale, messages })
