//这里做页面参数约定和说明，如果url没有携带requiredParams的参数则无法初始化页面
module.exports = {
  index: {
    title: '',
    requiredParams: {}
  }
  // index2: {
  //   title: '',
  //   requiredParams: {}
  // },
  // news: {
  //   title: '',
  //   requiredParams: {}
  // },
  // about: {
  //   title: '',
  //   requiredParams: {}
  // },
  // help: {
  //   title: '',
  //   requiredParams: {}
  // },
  // searchResult: {
  //   title: '',
  //   requiredParams: {}
  // },
  // newsDetail: {
  //   title: '',
  //   requiredParams: {}
  // },
  // helpContent: {
  //   title: '',
  //   requiredParams: {}
  // },
  // mobileQuestionModule: {
  //   title: '',
  //   requiredParams: {}
  // },
  // notice: {
  //   title: '',
  //   requiredParams: {}
  // }
}
