export default {
  // 通用翻译
  common: {
    confirm: '确定',
    return: '返回',
    cancel: '取消',
    noData: '暂无数据',
    reminder: '温馨提示',
    defaultInputHolder: '请输入',
    defaultSelectHolder: '请选择',
    copySuccess: '复制成功',
    copyNotSupport: '当前设备不支持复制',
    loadMore: '加载更多',
    noMore: '暂无更多',
    open: '打开',
    reqError: '请求失败，请稍后重试',
    stayTuned: '敬请期待',
    commonUse: '常用',
    goAppHandle: '请前往app进行操作',
    placeholder: '请输入搜索关键词',
    relation: '相关',
    result: '结果',
    count: '条',
    suggest: '推荐',
    relief: '免责声明：上述内容仅为发布者个人观点，不构成盈领或本社区的任何投资建议，盈领或本社区不承担因上述发布者言论引起的任何损失和损害。',
    publishTime: '发布时间',
    scanCodeTips: '扫码下载APP',
    noSearch: '抱歉，未找到相关信息',
    questionModule: '问题分类',
    downLoadText: '下载',
    languageChoice: '语言选择',
    weixinDownloadTips: '请点击右上角按钮，选择在浏览器中打开',
    openDownloadTips1: '请点击右上角',
    openDownloadTips2: '选择浏览器打开'
  },

  router: {
    winbull: '盈领信息',
    news: '资讯',
    aboutUs: '关于我们',
    helpCenter: '帮助中心',
    newsDetail: '资讯详情',
    searchResult: '搜索结果',
    notice: '消息通知',
    downLoadApp: '下载APP',
    questionModule: '问题模块'
  },

  header: {
    home: '首页',
    news: '资讯',
    aboutUs: '关于我们',
    helpCenter: '帮助中心',
    joinUs: '加入盈领',
    zhCn: '简体',
    zhHk: '繁体'
  },

  footer: {
    connectUs: '联系我们',
    warning: '市场有风险，投资需谨慎',
    email: '电邮查询',
    fwtk: '服务条款',
    yszc: '隐私政策',
    ba: '粤ICP备2022013020号',
    cy: 'Copyright © 盈领信息技术（深圳）有限公司',
    tradeDay: '交易日',
    winbull: '盈领信息',
    tksm: '条款声明',
    serviceCall: '客服电话',
    officeTime: '办公时间'
  },

  home: {
    bannerText1: '知识型',
    bannerText2: '证券服务平台',
    bannerDesc: '港股行情资讯实盘交流社区',
    our: '我们的',
    special: '特色',
    specialTitle11: '互动',
    specialTitle12: '社区',
    specialDesc1: {
      desc1: '社区有温度内容有深度',
      desc2: '大咖互动接触财富快人一步'
    },
    specialTitle21: '智能',
    specialTitle22: '交易',
    specialDesc2: {
      desc1: '提供智能盯盘、财报解读、',
      desc2: '投资观点等多重交易策略工具'
    },

    specialTitle31: '港股',
    specialTitle32: 'LV2高级行情',
    specialDesc3: {
      desc1: '全面覆盖港股、美股实时行情',
      desc2: '买卖盘口实时跟踪'
    },
    specialTitle41: '7x24小时',
    specialTitle42: '资讯',
    specialDesc4: {
      desc1: '提供全球资讯，股票异动信息',
      desc2: '让您的投资决策更精准'
    },
    featureTitle: '专业实用交易系统',
    featureDesc: '精准辅助投资决策',
    featureTitle2: '以帮助客户盈利为目标',
    featureDesc2: '智能盯盘，智盈策略',
    choiceTitle: '为什么选择盈领',
    choiceReason: {
      title: '快人一步',
      desc: '毫秒级下单速度,助您第一时间掌握交易先机',
      title2: '一站式交易',
      desc2: '支持港股、美股、基金等投资标的,享受多元投资体验',
      title3: '极致客户服务',
      desc3: '7*24小時在线客服,一对一解答疑问,专业放心'
    }
  },

  aboutUs: {
    overview: '公司概况',
    overviewDesc: '盈领信息技术(深圳)有限公司,主要从事互联网科技研发，为领先的IT解决方案服务提供商，团队拥有丰富的券商以及互联网产品运营经验、卓越的产品研发和创新能力，始终秉承“服务客户，体验至上”的理念，不断打磨产品功能，打造有温度的互动社区和金融科技生态圈。',
    address: '公司地址',
    addressItemTitle2: '盈领信息技术(深圳)有限公司',
    addressItemDesc2: '地址：深圳市福田区京基滨河时代大厦A座2201'
  },

  icp: {
    companyName: '盈领信息技术(深圳)有限公司',
    introText1: '是一家专注于',
    introText2: '金融数据',
    introText3: '及',
    introText4: '系统集成服务',
    introText5: '的供应商',
    companyDesc: '公司团队拥有卓越的产品研发与创新能力,始终秉持「服务客户，体验至上」的理念，为客户提供一站式解决方案。 '
  }
}
