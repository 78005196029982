export default {
  // 通用翻译
  common: {
    confirm: '確定',
    return: '返回',
    cancel: '取消',
    noData: '暫無數據',
    reminder: '溫馨提示',
    defaultInputHolder: '請輸入',
    defaultSelectHolder: '請選擇',
    copySuccess: '複製成功',
    copyNotSupport: '當前設備不支持複製',
    loadMore: '加載更多',
    noMore: '暫無更多',
    open: '打開',
    reqError: '請求失敗，請稍後重試',
    stayTuned: '敬請期待',
    commonUse: '常用',
    goAppHandle: '請前往app進行操作',
    placeholder: '請輸入搜索關鍵詞',
    relation: '相關',
    result: '結果',
    count: '條',
    suggest: '推薦',
    relief: '免責聲明：上述內容僅為發佈者個人觀點，不構成盈領或本社區的任何投資建議，盈領或本社區不承擔因上述發佈者言論引起的任何損失和損害。',
    joinTitle: '令您投資Sure Win',
    situation: '崗位',
    noAccount: '暫無崗位信息',
    publishTime: '發佈時間',
    scanCodeTips: '掃碼下載APP',
    noSearch: '抱歉，未找到相關信息',
    questionModule: '問題分類',
    languageChoice: '語言選擇',
    downLoadText: '下載',
    weixinDownloadTips: '請點擊右上角按鈕，選擇在瀏覽器中打開',
    openDownloadTips1: '請點擊右上角',
    openDownloadTips2: '選擇瀏覽器打開'

  },

  router: {
    winbull: '盈領信息',
    news: '資訊',
    aboutUs: '關於我們',
    helpCenter: '幫助中心',
    newsDetail: '資訊詳情',
    searchResult: '搜索結果',
    notice: '消息通知',
    downLoadApp: '下載APP',
    questionModule: '問題模塊'
  },

  header: {
    home: '首頁',
    news: '資訊',
    aboutUs: '關於我們',
    helpCenter: '幫助中心',
    joinUs: '加入盈領',
    zhCn: '簡體',
    zhHk: '繁體',
  },

  footer: {
    connectUs: '聯繫我們',
    warning: '市場有風險，投資需謹慎',
    fwtk: '服務條款',
    yszc: '私隱政策',
    ba: '粵ICP備2022013020號',
    cy: 'Copyright © 盈領信息技術（深圳）有限公司',
    winbull: '盈領信息',
    tksm: '條款申明',
    serviceCall: '客服電話',
    officeTime: '辦公時間'
  },

  home: {
    bannerText1: '知識型',
    bannerText2: '證券服務平台',
    bannerDesc: '港股行情資訊實盤交流社區',
    our: '我們的',
    special: '特色',
    specialTitle11: '互動',
    specialTitle12: '社區',
    specialDesc1: {
      desc1: '社區有溫度內容有深度',
      desc2: '大咖互動接觸財富快人一步'
    },
    specialTitle21: '智能',
    specialTitle22: '交易',
    specialDesc2: {
      desc1: '提供智能盯盤、財報解讀、',
      desc2: '投資觀點等多重交易策略工具'
    },

    specialTitle31: '港股',
    specialTitle32: 'LV2高級行情',
    specialDesc3: {
      desc1: '全面覆蓋港股、美股實時行情',
      desc2: '買賣盤口實時跟蹤'
    },

    specialTitle41: '7x24小時',
    specialTitle42: '資訊',
    specialDesc4: {
      desc1: '提供全球資訊，股票異動信息',
      desc2: ' 讓你的投資決策更精准'
    },
    featureTitle: '專業實用交易系統',
    featureDesc: '精準輔助投資決策',
    featureTitle2: '以幫助客戶盈利為目標',
    featureDesc2: '智能盯盤，智盈策略',
    choiceTitle: '為什麼選擇盈領',
    choiceReason: {
      title: '快人一步',
      desc: '毫秒級下單速度,助您第一時間掌握交易先機',
      title2: '一站式交易',
      desc2: '支持港股、美股、基金等投資標的,享受多元投資體驗',
      title3: '極致客戶服務',
      desc3: '7*24小時在線客服,一對一解答疑問,專業放心'
    }

  },

  aboutUs: {
    overview: '公司概況',
    overviewDesc: '盈領信息技術(深圳)有限公司,主要從事互聯網科技研發，為領先的IT解決方案服務提供商，團隊擁有豐富的券商以及互聯網產品運營經驗、卓越的產品研發和創新能力，始終秉承「服務客戶，體驗至上」的理念，不斷打磨產品功能，打造有溫度的互動社區和金融科技生態圈。',
    address: '公司地址',
    addressItemTitle2: '盈領信息技術(深圳)有限公司',
    addressItemDesc2: '地址：深圳市福田區京基濱河時代大廈A座2201',
  },

  icp: {
    companyName: '盈領信息技術(深圳)有限公司',
    introText1: '是一家專注於',
    introText2: '金融數據',
    introText3: '及',
    introText4: '系統集成服務',
    introText5: '的供應商',
    companyDesc: '公司團隊擁有卓越的產品研發與創新能力,始終秉持「服務客戶，體驗至上」的理念，為客戶提供一站式解決方案。'
  }
}
