<template>
  <div :class="['search-box', { 'box-shadow': isClickSearch }]">
    <van-search
      v-model="searchValue"
      placeholder="搜索"
      :left-icon="require('@imgs/common/icon_search_gray.png')"
      :clearable="false"
      :clear-trigger="'focus'"
      :right-icon="rightIcon"
      @click-left-icon="searchList"
      @click-right-icon="clearSerch"
      @search="searchList"
    />
    <template>
      <div class="search-list" :style="{ maxHeight: totalCount > 20 ? '400px' : '430px' }">
        <template v-if="!list.length">
          <div class="no-search">
            <template v-if="confirmSearchValue"> 未找到与<span class="key-word">{{ confirmSearchValue }}</span>相关的结果</template>
            <template v-else>{{$t('common.noSearch')}}</template>
          </div>
        </template>
        <template v-else>
          <div class="all-result">
            搜索到
            <span class="key-word">{{ confirmSearchValue }}</span>相关的结果共
            <span class="count">
              {{ totalCount
              }}
            </span>条
          </div>

          <template v-if="searchType === 1">
            <div v-for="item in list" :key="item.id" class="list" @click="toDetail(item.id)">
              <template v-if="setHighLignt(item)!==''">
                <div class="content" v-html="setHighLignt(item)" />
                <div class="user">
                  <span v-if="item.userName">{{ item.userName }}</span>
                  <span class="time">{{ formatterTime(item.issueTime) }}</span>
               </div>
              </template>
            </div>
          </template>
          <template v-else>
            <div v-for="item in list" :key="item.id" class="list" @click="toDetail(item)">
              <div class="help-title" v-html="setHighLignt(item)" />
              <div class="help-content text-overflow-2" v-html="setHelpContent(item.content)" />
            </div>
          </template>
        </template>
        <div class="show-more" @click="toResultList" v-if="totalCount > 20">查看更多</div>
      </div>
    </template>
  </div>
</template>

<script>
  import { Search } from 'vant'
  import { searchArticle, helpSearch } from '@/api'
  import { searchStyle, getOnlyText } from '@/utils/quill.js'
  import { setTimeFormatter, debounce } from '@/utils/common'
  export default {
    name: 'search-box',
    props: {
      pageIndex: {
        type: Number,
        default: 1
      },
      tabIndex: {
        default: 0,
        type: Number
      },
      searchType: {
        type: Number,
        default: 1 // 判断搜索类型 1：资讯 2:帮助
      },
      categoryId: [String, Number],
      moduleId: [String, Number]
    },
    computed: {
      formatterTime() {
        return function(time) {
          return setTimeFormatter(time, 'MM/DD')
        }
      },
      setHelpContent() {
        return function(content) {
          const htmlText = this.getHelpContentText(content)
          console.log(htmlText)
          return searchStyle(this.searchValue, htmlText)
        }
      },
      setHighLignt() {
        return function(data) {
          const content = getOnlyText(data.title || data.content)
          return searchStyle(this.searchValue, content)
        }
      }
    },
    data() {
      return {
        isClickSearch: false,
        searchValue: '',
        confirmSearchValue: '',
        totalCount: 0,
        list: [],
        rightIcon: '',
        isMobile: false
      }
    },
    components: {
      [Search.name]: Search
    },
    watch: {
      searchValue(nVal) {
        this.list = []
        this.totalCount = 0
        const str = nVal.replace(/\s*/g, '')
        if (str.trim() !== '') {
          this.searchList()
          this.rightIcon = require('@imgs/common/icon_close.png')
        } else {
          this.confirmSearchValue = ''
        }
      },
      categoryId(newVal, oVal) {
        if (newVal !== oVal) {
          this.isClickSearch = false
        }
      }
    },
    methods: {
      searchList: debounce(function() {
        if ((this.searchValue).trim() === '') return
        if (this.isMobile) {
          this.toResultList()
        }
        if (this.searchType === 1) {
          this.articleList()
        } else {
          this.helpList()
        }
      }, 500),
      articleList() {
        searchArticle({
          pageIndex: this.pageIndex,
          pageSize: 20,
          title: this.searchValue
        }).then(res => {
          this.isClickSearch = true
          if (res.code === 0) {
            console.log(res)
            this.confirmSearchValue = this.searchValue
            this.list = res.data.articleQuotesList || []
            this.totalCount = res.data.total
          }
        }).catch(err => {
          console.log(err)
        })
      },
      helpList() {
        helpSearch({
          page: this.pageIndex,
          size: 20,
          title: this.searchValue,
          channel: 'INFORMATION_OFFICIAL'
        }).then(res => {
          this.isClickSearch = true
          if (res.code === 0) {
            this.confirmSearchValue = this.searchValue
            this.list = res.data.records || []
            this.totalCount = res.data.total
          }
        })
      },
      getHelpContentText(text) {
        if (!text) return
        const res1 = new RegExp('<.+?>', 'g')
        const msg = text.replace(res1, '')
        return msg
      },
      toDetail(data) {
        if (this.searchType === 1) {
          window.location.href = `./newsDetail.html?id=${data}&type=${this.tabIndex}&categoryId=${this.categoryId}`
        } else {
          window.location.href = `./helpContent.html?id=${data.id}&moduleId=${data.parentIds[0]}&length=${data.parentIds.length}&pid=${data.parentIds[data.parentIds.length - 1]}`
        }
      },
      clearSerch() {
        this.searchValue = ''
        this.rightIcon = ''
        this.isClickSearch = false
      },
      toResultList() {
        window.location.href = `./searchResult.html?key=${this.searchValue}&type=${this.searchType}${this.categoryId ? '&categoryId=' + this.categoryId : ''}&name=${this.searchType === 2 ? 'help' : 'news'}`
      }
    },
    mounted() {
      this.isMobile = window.innerWidth <= 768
      window.addEventListener('resize', debounce(() => {
        this.isMobile = window.innerWidth <= 768
        // 移动端自适应
        if (this.searchValue && this.isMobile) {
          this.toResultList()
        }
      }, 50))
    }
  }
</script>
<style lang="scss" scoped>
.search-box {
  width: 300px;
  height: 36px;
  border-radius: 4px;
  overflow: hidden;
  flex: 0 0 auto;

  ::v-deep .van-search {
    padding: 0;
    height: 100%;

    .van-search__content {
      background: #fff;
      height: 100%;
      width: 100%;
      padding: 0 12px;

      .van-cell {
        padding: 0;
        display: flex;
        align-items: center;

        .van-field__control {
          height: 36px;
        }

        .van-field__left-icon {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }
  }

  &.box-shadow {
    width: 420px;
    max-height: 536px;
    min-height: 153px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    z-index: 10;
    padding: 20px 24px 16px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 16px;
    height: auto;

    .van-search {
      height: 52px;
      padding-bottom: 16px;

      .van-search__content {
        background: #f9f9f9;

        .van-field__control {
          color: #121212;
        }
      }
    }

    .key-word {
      color: #0691ff;
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
    }

    .no-search {
      font-size: 12px;
      color: #999;
      margin-top: 40px;
      text-align: center;
    }

    .all-result {
      font-size: 12px;
      color: #999;
      margin-top: 10px;
    }

    .count {
      display: inline-block;
      color: #121212;
      margin-left: 5px;
      margin-right: 5px;
    }

    .list {
      margin-top: 24px;
      cursor: pointer;

      .content {
        font-size: 14px;
        color: #121212;
        line-height: 20px;
        max-height: 40px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; //行数需设置
        line-clamp: 2;
        -webkit-box-orient: vertical;

      }

      .user {
        font-size: 12px;
        color: #999;
        margin-top: 8px;

        span {
          display: inline-block;
          margin-right: 20px;
        }
      }
    }

    .search-list {
      max-height: 430px;
      min-height: 60px;
      overflow-y: auto;
    }
  }

  .show-more {
    font-size: 14px;
    color: #1890ff;
    text-align: center;
    margin-top: 24px;
    cursor: pointer;
  }
  .help-title {
    font-size: 14px;
    color: #121212;
    margin-bottom: 4px;
    font-weight: 500;
  }
  .help-content {
    color: #666;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    table {
      display: none;
    }
  }
}
::v-deep table{
  display: none!important;
}
</style>
