<template>
  <div class="text-content" >
    <div ref="content" v-html="contentHtml" @click.stop="showImages" />
    <previewImage
      v-if="isCheckedPhotos"
      :showIndex="showImagesIndex"
      :imgList="zoomImgList"
      @closePhotoModal="checkPhoto" />
  </div>
</template>
<script>
  import previewImage from '@/pages/news/components/previewImage.vue'
  export default {
    name: 'text-content',
    props: {
      contentHtml: {
        type: String,
        default: ''
      }
    },
    data(){
      return {
        isCheckedPhotos: false,
        zoomImgList: [],
        showImagesIndex: 0
      }
    },
    components: {
      previewImage
    },
    methods: {
      checkPhoto(data) {
        this.isCheckedPhotos = data
      },
      showImages(e) {
        this.zoomImgList = []
        if (e.target.tagName === 'IMG') {
          const imgList = this.$refs.content.getElementsByTagName('img')
          for (let i = 0; i < imgList.length; i++){
            const src = imgList[i].src
            this.zoomImgList.push(src)
          }
          const currentSrc = e.target.currentSrc
          this.showImagesIndex = this.zoomImgList.findIndex(item => {
            return currentSrc === item
          })
          this.isCheckedPhotos = true
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.text-content{
 min-height: 55vh;
 border-radius: 4px;
 line-height: 28px;
 color: #121212;
 font-size: 16px;
 text-align: left;
 word-break: break-all;
 white-space: pre-wrap;
 ::v-deep img{
  max-width: 460px;
  border-radius: 4px;
  margin: 28px auto;
   &.ql-emoji{
    width: 22px;
    margin: 0;
   }
 }
 ::v-deep a{
  pointer-events:none
 }
}
@media screen and (max-width: 810px) {
 .text-content{
   width: 100%;
   padding-top: 24px;
   background-color: #fff;
   margin: 0;
   min-height: auto;
   ::v-deep img{
     max-width: 100%;
   }
 }

}

</style>
