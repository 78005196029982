import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-hk'
const utc = require('dayjs/plugin/utc')
const isToday = require('dayjs/plugin/isToday')
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const lang = window.localStorage.getItem('application_language') || 'zh_CN'
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(isToday)
dayjs.locale(lang.toLowerCase())

export default dayjs
