const defaultErrMsg = 'Request failed, Please try again'

/**
 * 获取错误信息
 * @param {*} error
 * @param {*} defaultMessage 默认错误信息语句
 * @returns
 */
export default function(error, defaultMessage = defaultErrMsg) {
  if (error === undefined) return defaultMessage
  if (typeof error === 'string') return error
  return error.msg || defaultMessage
}
