import Axios from 'axios'
import Config from '@/config/index.js'
import i18n from '@/language'
import { getToken } from '@/utils/common.js'
import { Notify } from 'vant'
import getErrorMessage from '@/utils/get-error-message.js'

const service = Axios.create({
  baseURL: Config.apiUrl + '/' + Config.apiPrefix,
  headers: {
    Accept: '*/*'
  },
  timeout: Config.timeout
})

service.defaults.retry = Config.requestRetry
service.defaults.retryDelay = Config.requestRetryDelay
// let LoadingInstance = null;

// request拦截器【请求处理】
service.interceptors.request.use(
  request => {
    const token = getToken()
    if (token) request.headers['Authorization'] = `Bearer ${token}`
    request.params = { ...request.params, lang: i18n.locale || 'zh_CN' }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

// respone拦截器【相应处理】
service.interceptors.response.use(
  response => {
    const {
      data,
      status,
      config: { showError = true }
    } = response
    if (status === 200) {
      return data
    } else {
      showError && Notify({ type: 'warning', message: getErrorMessage(data) })
      return Promise.reject(data)
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
