<template>
  <div class="limit-wrap">
    <div class="limit-padding"  :style="{ background: bg }">
      <div class="limit-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'limit-wrap',

    props: {
      bg: {
        type: String,
        default: '#f5f5f5'
      }
    }
  }
</script>

<style lang="scss" scoped>
.limit-wrap {

  .limit-padding {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    background: #fff;
  }

  .limit-content {
    position: relative;
    width: 100%;
    height: auto;
  }
}
@media (min-width:769px) and (max-width:1024px) {
  .limit-wrap {
    .limit-padding {
      padding: 0 112px;
    }
  }
}
@media (min-width:768px) and (max-width:768px) {
  .limit-wrap {
    .limit-padding {
      padding: 0 35px;
    }
  }
}
@media screen and (max-width:767px) {
  .limit-wrap {
    .limit-padding {
      padding: 0 20px;
    }
  }
}
</style>
